<template>
  <div class="attachments">
    <Header back="返回" title="合同列表" index="首页" titleOne="合同管理" titleTwo="合同列表" beforeTitle="新增/编辑" />
    <div
      class="content"
      v-loading="loading"
      element-loading-text="附件上传中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <h1>合同信息</h1>
      <el-form
        :rules="contarctRule"
        ref="contarctRule"
        :model="contarctform"
        label-width="80px"
        :label-position="labelPosition"
      >
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="contarctform.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="合同名称" prop="contract_name">
          <el-input v-model="contarctform.contract_name" placeholder="请输入合同名称"></el-input>
        </el-form-item>
        <el-form-item label="合同编号类型" prop="prefix">
          <el-select
            v-model="contarctform.prefix"
            placeholder="请选择合同编号类型"
            :disabled="idStatus"
            v-if="!$route.query.id"
          >
            <el-option label="SZHC1" value="SZHC1"></el-option>
            <el-option label="SZWS1" value="SZWS1"></el-option>
            <el-option label="YPKJ1" value="YPKJ1"></el-option>
            <el-option label="YPCG1" value="YPCG1"></el-option>
            <el-option label="SZYMT1" value="SZYMT1"></el-option>
            <el-option label="SZZX1" value="SZZX1"></el-option>
            <el-option label="YPTZ1" value="YPTZ1"></el-option>
            <el-option label="ZMZL1" value="ZMZL1"></el-option>
            <el-option label="HTRC1" value="HTRC1"></el-option>
            <el-option label="GQB1" value="GQB1"></el-option>
          </el-select>
          <el-input
            :disabled="idStatus"
            v-if="$route.query.id"
            v-model="contarctform.ht_num"
            placeholder="合同编号类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="签约时间" prop="down_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="contarctform.down_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同开始时间" prop="start">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="contarctform.start"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同结束时间" prop="end">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="contarctform.end"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同金额(元)">
          <el-input v-model="contarctform.amount" placeholder="请输入合同金额"></el-input>
        </el-form-item>
        <el-form-item label="渠道商">
          <el-input v-model="contarctform.channel" placeholder="请输入渠道商"></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="contarctform.contact" placeholder="请输入客户联系人"></el-input>
        </el-form-item>
        <el-form-item label="客户联系方式">
          <el-input v-model="contarctform.tel" placeholder="请输入客户联系方式"></el-input>
        </el-form-item>
        <el-form-item label="签订部门">
          <el-select
            v-model="contarctform.sign_org"
            @visible-change="signChange"
            @change="getccid"
            placeholder="请选择签订部门"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签订人">
          <el-select v-model="contarctform.sign_person" placeholder="请选择签订人">
            <el-option
              v-for="item in moptions"
              :key="item.id"
              :label="item.true_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="合同说明" style="width: 100%">
          <el-input
            type="textarea"
            v-model="contarctform.explain"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>
        <!--<div class="ImportTop" style="position: relative">
          <el-upload
            class="upload-demo"
            v-model="contarctform.file_name"
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
            :on-change="handleChange"
            :on-success="onSuccess"
          >
            <el-button type="primary" class="ChooseFile">请选择文件上传</el-button>
          </el-upload>
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
          <el-link
            style="color: #0f38ff"
            v-model="contarctform.pdf_path"
            v-if="contarctform.pdf_path"
            :href="userSite + this.contarctform.pdf_path"
            target="_blank"
          >查看附件</el-link>
           <el-progress
            color="#0F38FF"
            style="position: absolute; top: 0px"
            :width="40"
            v-show="contarctform.showProcess"
            type="circle"
            :percentage="contarctform.processLength"
          ></el-progress>
        </div>-->
        <el-table ref="tableRef" :data="contarctform.project" style="width: 100%" v-if="showTable">
          <el-table-column width="50">
            <template slot="header">
              <button type="button" @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="contarctform.project.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="项目类型">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.product_type"
                placeholder="请选择"
                @change="
                  (val) => {
                    getproductId(val, scope.$index,scope.row);
                  }
                "
              >
                <el-option v-for="item in type" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="项目">
            <template slot-scope="scope">
              <el-select v-model="scope.row.product_id" placeholder="请选择" ref="select">
                <el-option
                  v-for="item in productId[scope.$index]"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="是否附加项目">
            <template slot-scope="scope">
              <el-select v-model="scope.row.is_additional" placeholder="请选择" ref="select">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="meber_id" width="70%">
            <template slot-scope="scope">
              <el-button class="cz info edit" @click="delEdit(scope.row.id)" type="text">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      showTable: true,
      index: '',
      options: [],//签订部门
      moptions: [],//签订人
      loading: false,
      isDisable: false,
      labelPosition: "top",
      userSite: site.userSite,
      contarctform: {
        true_name: '',
        token: '',
        company_name: "",
        contract_name: "",
        ht_num: "",
        down_time: "",
        start: "",
        end: "",
        amount: "",
        channel: "",
        contact: "",
        tel: "",
        explain: "",
        project: [],
        com_id: "",
        file_name: "",
        url: "",
        file_id: "",
        showProcess: false,
        processLength: 0,
      },
      fileList: [],
      results: [],
      timeout: null,
      contarctRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        contract_name: [
          {
            required: true,
            message: "请输入合同名称",
            trigger: "blur",
          },
        ],
        ht_num: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
          },
        ],
        down_time: [
          {
            required: true,
            message: "请选择签约时间",
            trigger: "change",
          },
        ],
        start: [
          {
            required: true,
            message: "请选择合同开始时间",
            trigger: "change",
          },
        ],
        product_type: [
          {
            required: true,

            trigger: "change",
          },
        ],
        product_id: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      type: [],
      productId: new Array(),
      list: [],
      idStatus: true,
    };
  },
  watch: {
    "contarctform.sign_org": {
      handler (val, oval) {
        if (this.meber_id) {
          this.getccid(val);
        }
      },
      deep: true,
    },
  },
  created () {
    this.getType();
    this.getitemId();
    this.contarctform.token = localStorage.getItem('token')
    if (this.$route.query.id) {
      this.idStatus = true
    } else {
      this.idStatus = false
    }
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/contract/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          res.data.project.forEach((item, index) => {
            console.log(item, index);
            item.product_type = +item.product_type;
            this.getproductId(item.product_type, index);
          });
          this.showTable = false
          this.contarctform = res.data;
          this.$nextTick(() => {
            this.showTable = true
          })
        });
    }
  },
  methods: {
    signChange (item) {
      if (this.meber_id && item == false) {
        this.contarctform.sign_person = ''
      }
    },
    delEdit (id) {
      console.log(id, '888')
      this.$router.push({ path: '/contract/edit', query: { id: id } })
    },
    // 获取部门
    getitemId () {
      this.axios
        .get("/api/organization/select_organization", { params: { has_manager: 1 } })
        .then((res) => {
          this.options = res.data;
        });
    },
    getccid (id) {
      console.log(id);
      this.axios
        .get("/api/member/select_member", { params: { organization_id: id } })
        .then((res) => {
          this.moptions = res.data;
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    getType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.type = res.data;
      });
    },
    getproductId (id, index, row) {
      // if (row) {
      //   row.product_id = ''
      // }
      this.axios
        .get("/api/policy/select_product", {
          params: {
            type: id,
          },
        })
        .then((res) => {
          this.$set(this.productId, index, res.data)
          // this.$forceUpdate();
        });
    },

    httpRequest (param) {
      // console.log(param,'param');
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("model", "contract");
      this.upload("/api/file/upload_file", form).then((res) => {
        this.contarctform.pdf_path = res.data.url;
        param.onSuccess(res);
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.loading = false;
        } else if (res.code !== 1) {
          this.message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    upload (url, params) {
      this.loading = true;
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    handleChange (file) {
      if (file.status === "ready") {
        this.contarctform.processLength = 0;
        this.contarctform.showProcess = true;
        const intrtval = setInterval(() => {
          if (this.contarctform.processLength >= 99) {
            clearInterval(intrtval);
            return;
          }
          this.contarctform.processLength += 1;
        }, 20);
      }
    },
    onSuccess (response, file) {
      if (file.status === "success") {
        this.contarctform.processLength = 100;
        this.contarctform.showProcess = false;
      }
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.contarctform.com_id = item.id;
      this.contarctform.company_name = item.value;
    },

    onSubmit () {
      this.$refs.contarctRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/contract/store", this.contarctform)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.btnLoading = false
              this.isDisable = true
              this.$router.go(-1);
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.warning('请完善必填项')
        }
      });
    },
    addLine () {
      //添加行数
      this.contarctform.project.push({});
    },
    handleDelete (index) {
      //删除行数
      this.contarctform.project.splice(index, 1);
      this.productId.splice(index, 1)

    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.contarctform.project));
    },
    returnPage () {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

.ImportTop span {
  color: #a6abc7;
}
div/deep/.el-icon-loading {
  font-size: 30px;
  color: #fff;
}
div/deep/.el-loading-text {
  color: #fff;
  font-size: 16px;
}
.info {
  padding: 8px 11px !important;
}
</style>
